<template>

  <div  class="customize__index m-0 p-0" >
    <!-- Loader -->
    <div v-if="isLoading">
      <LoaderMain v-if="isLoading"  />
    </div>

    <!-- All Pages -->
    <div v-else>
      <div v-if="KeyNotValid">
        <transition name="quizFade" appear>
        <InvalidKeyPage />
        </transition>
      </div>
      <div v-else>
        <div v-if="getPreviewSettings && getPreviewSettings.expireStatus">
        <transition name="quizFade" appear>
          <ExpiredPage />
           </transition>
        </div>
        <div v-else >
          <div
            v-if="CurrentSelectedPage.value == 'getStartedPage'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'getStarted'"
          >
          <DewhaGetStartedPage1  :apiToken="QuizKey" v-if="getPreviewSettings.quizId==dehwaQuizEnv"></DewhaGetStartedPage1>
             <GetStartedPreview
             v-else
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :RoutePage='getCurrPageRouteName'
            />
          
          </div>
          <div
            v-if="CurrentSelectedPage.value == 'singleAnswer'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'single'"
          > 
          <KozimaSinglePageComp :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :RoutePage='getCurrPageRouteName' v-if="isKozimaGameQuestion"></KozimaSinglePageComp>
             <DewhaSinglePage :apiToken="QuizKey" v-else-if="getPreviewSettings.quizId==dehwaQuizEnv"></DewhaSinglePage>
             <ColonBroomQuestion 
          :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :RoutePage='getCurrPageRouteName' v-else-if="isColonBroomSingleQuiz.quizId==getPreviewSettings.quizId"></ColonBroomQuestion>
             <SingleAnswerPreview v-else
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :RoutePage='getCurrPageRouteName'
            />
          
          </div>
        
           <div
            v-if="CurrentSelectedPage.value == 'multipleAnswer'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'multi'"
          >
          <ColonBroomMultiQuestion v-if="isColonBroomSingleQuiz.quizId==getPreviewSettings.quizId"  :isDesktopView="isDesktopView"
              :apiToken="QuizKey" 
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :RoutePage='getCurrPageRouteName'></ColonBroomMultiQuestion>
             <MultipleAnswerPreview
             v-else
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey" 
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :RoutePage='getCurrPageRouteName'
            />
           
           
          </div>
     

          <div
            v-if="CurrentSelectedPage.value == 'leadPage'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'leadPage'"
          >
          <ColonBroomBMI class="w-100" v-if="isColonBroomSingleQuiz.quizId==getPreviewSettings.quizId&&CurrentSelectedPage.id==isColonBroomSingleQuiz.bmiQuestion"></ColonBroomBMI>
          <ColonBroomLeadPage :apiToken="QuizKey" v-else-if="isColonBroomSingleQuiz&&CurrentSelectedPage.id==isColonBroomSingleQuiz.emailQuestion"></ColonBroomLeadPage>
          <DewhaLeadPage  :apiToken="QuizKey" v-else-if="getPreviewSettings.quizId==dehwaQuizEnv" ></DewhaLeadPage>
             <LeadPreview
             v-else
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :RoutePage='getCurrPageRouteName'
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
            />
 
          </div>
          

           <div
            v-if="CurrentSelectedPage.value == 'resultPage'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'result'"
          >
           <DewhaResultPage :apiToken="QuizKey"  v-if="getPreviewSettings.quizId==dehwaQuizEnv"></DewhaResultPage>
             <ResultPreview
             v-else
             :data-aos-delay="100" 
              :isDesktopView="isDesktopView"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :apiToken="QuizKey"
              :RoutePage='getCurrPageRouteName'
              :resultPreviewProducts="resultPreviewProducts"
            />
           
           
          </div>
         
            <div
            v-if="CurrentSelectedPage.value == 'stripePage'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'stripe'"
          >
             <StripePreview
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :RoutePage='getCurrPageRouteName'
            />
           
          </div>
            <div
            v-if="CurrentSelectedPage.value == 'sliderPage'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'slider'"
          >
             <SliderComp
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :RoutePage='getCurrPageRouteName'
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
            />
           
          </div>
        

           <div
            v-if="CurrentSelectedPage.value == 'endQuiz'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'endQuiz'"
          >
             <EndQuizPreview
              :isDesktopView="isDesktopView"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :apiToken="QuizKey"
              :RoutePage='getCurrPageRouteName'
            />
           
          </div>
        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import DewhaGetStartedPage1 from "../quizPreviewComp/specialClients/Dewha/DewhaGetStartedPage1.vue";
import DewhaSinglePage from "../quizPreviewComp/specialClients/Dewha/DewhaSinglePage.vue";
import ColonBroomQuestion from "../quizPreviewComp/specialClients/ColonBroom/ColonBroomSingleQuestion.vue";
import ColonBroomMultiQuestion from "../quizPreviewComp/specialClients/ColonBroom/ColonBroomMultiQuestion.vue";
import ColonBroomLeadPage from "../quizPreviewComp/specialClients/ColonBroom/ColonBroomLeadPage.vue";
import ColonBroomBMI from "../quizPreviewComp/specialClients/ColonBroomBMI.vue";
export default {
  components: {
    DewhaSinglePage,
    DewhaGetStartedPage1,
    ColonBroomQuestion,
    ColonBroomMultiQuestion,
    ColonBroomLeadPage,
    ColonBroomBMI,
    DewhaLeadPage:()=> import(/* webpackPrefetch: true */ '../quizPreviewComp/specialClients/Dewha/DewhaLeadPage.vue'),
    DewhaResultPage:()=> import(/* webpackPrefetch: true */ '../quizPreviewComp/specialClients/Dewha/DewhaResultPage.vue'),
    LoaderMain:()=> import(/* webpackPrefetch: true */ '../../components/LoaderComps/LoaderMain.vue'),
    KozimaSinglePageComp: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/specialClients/KozimaSinglePageComp.vue"
      ),
    GetStartedPreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/getStartedComp.vue"
      ),
    SingleAnswerPreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/SingleAnswerComp.vue"
      ),
    MultipleAnswerPreview: () =>
      import(
        /* webpackPrefetch: true */"../quizPreviewComp/MultipleAnswerComp.vue"
      ),
    LeadPreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/LeadComp.vue"
      ),
    ResultPreview: () =>
      import(
        /* webpackPrefetch: true */"../quizPreviewComp/ResultComp.vue"
      ),
    EndQuizPreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/EndQuiz.vue"
      ),
    InvalidKeyPage: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/InvalidKeyPage.vue"
      ),
    ExpiredPage: () =>
      import(
       /* webpackPrefetch: true */"../quizPreviewComp/ExpiredPage.vue"
      ),
    StripePreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/StripePreviewComp.vue"
      ),
      SliderComp: () => import(/* webpackPrefetch: true */ '../quizPreviewComp/SliderComp.vue'),
   
},
  data() {
    return {
      count: 0,
      countComp: 0,
      isDesktopView: true,
      isLoading: false,
      KeyNotValid: false,
      QuizKey: "",
      animationType:"SlideIn",
      windowWidth: window.innerWidth,
      resultPreviewProducts: [],
      audioContext: null,
      audioBuffer: null,
      audioSource: null,
      audioPlayed: false,
      source:null,
      gainNode:null,
      buffer:null,
    };
  },
  computed: {
    isColonBroomSingleQuiz(){
      let isColonBroom
      if(this.getEnvConst?.COLONBROOM)
    {
      console.log(this.getEnvConst?.COLONBROOM)
      isColonBroom=JSON.parse(this.getEnvConst?.COLONBROOM)
    }
      return isColonBroom
    },
    dehwaQuizEnv(){
      return process.env?.VUE_APP_SLIDER_DEWHA_QUIZID;
    },
    isKozimaGameQuestion(){
      if(this.getEnvConst?.KOZIMA_GAME){
        const kozima = JSON.parse(this.getEnvConst?.KOZIMA_GAME)[0]
        return kozima.quizId==this.getPreviewSettings.quizId
      }
      else {
        return false
      }
    },
    isrealChartClientIds(){
      if(this.getEnvConstants){
        return [
        parseInt(this.this.getEnvConstants?.VUE_APP_ISRAELI_CHART_QUIZID??null),
        parseInt(this.this.getEnvConstants?.VUE_APP_ISRAELI_CHART_2_QUIZID??null),
      ]
      }
      else {
        return []
      }
    },
    ...mapGetters(["getSelectedPage", "getTotalPages", "getPreviewSettings","getTotalPreviewPages","getSelectedOptions",
      "getSavedLeadID","getEnvConst"]),
    CurrentSelectedPage() {
    
      return this.getSelectedPage;
    },
    getSelectedOptionsFromVuex(){
    return JSON.parse(JSON.stringify( this.getSelectedOptions));
    },
    getIsAnimationEnabled(){
      if (this.getPreviewSettings) return this.getPreviewSettings.animation.show;
       return null
    },
    getAnimationType(){
      if (this.getPreviewSettings) {
        let {direction} = this.getPreviewSettings.animation

        if(direction == 'LTR') return `slide-right`
        if(direction == 'RTL') return `slide-left`
        if(direction == 'TTB') return `slide-down`
        if(direction == 'BTT') return `slide-up`
        if(direction == 'POP') return `zoom-in`
        if(direction == 'FLIP') return `flip-left`
      }
       return null
    },
    getAnimationSpeedinMillis(){
      if (this.getPreviewSettings) {
        let { speed ,show } = this.getPreviewSettings.animation;

        if (typeof speed === "string" && show) {
          speed = parseFloat(speed);
          if (isNaN(speed)) {
            return 0;
          }
        } else{
          speed = 0.3
        }

        return speed * 1000;
      }
      return null;
    },
    isExpired() {
      if (this.getPreviewSettings) return this.getPreviewSettings.expireStatus;
      else{ return null}
    },
     GetCurrPageIndex(){
      
      if(this.getTotalPreviewPages && this.CurrentSelectedPage){
         let index = this.getTotalPreviewPages.findIndex(page => page.id == this.CurrentSelectedPage.id)
        return index > -1 ? index : null
      } else {
        return null
      }
    },
    getCurrPageRouteName(){
      return this.$route.name
    }
  },
  async created() {
   
    this.QuizKey = this.$route.params.id;
    const result_key = this.$route.query.result_key
    this.ResetStoredData();
   this.RESET_STATE();
   await this.$store.commit('RESET_FONT_FAMILY_TRACKER')
   await this.$store.commit('RESET_LOADEDFONTS')
    // await this.PopulatePreviewDataVuex(this.QuizKey);
    if (result_key) {
      await this.PopulateResultPreviewDataVuex(result_key);
    } else {
      await this.initializePreviewData();
      this.resetSavedLeadObj({
       address:{},
       custom_inputs:{},
            date:'',
            email:'',
            freeTextNote:[],
            full_name:'',
            website:'',
            organisation:'',
            phone_number:'',
            terms_conditions:false, fileUpload:[],
            quiz_analytic_id:null})
    }
   
            this.resetLeadCounter()
            this.setIsLoadingQuestion(false)
    this.resetPreviewPagesStack();
     this.resetPopulateTrackCounter()
    await this.resetSelectedOptions();
    await this.resetRangeSliders();
    if(this.isKozimaGameQuestion)
 {
  this.setupAudio();
  this.initializeAudioOnUserGesture()
 }
  },
  beforeDestroy() {
    this.stopAudio();
    if (this.audioContext) {
      this.audioContext.close();
    }
  },

 async mounted() {
  window.parent.postMessage({ page: "getStartedPage" }, "*");
    if (this.windowWidth >= 576) {
this.isDesktopView = true;
} else {
this.isDesktopView = false;
}
       if(localStorage.getItem("already_record_status")){
      localStorage.removeItem("already_record_status");
    }
    if(localStorage.getItem("already_record_email")){
      localStorage.removeItem("already_record_email");
    }
    if(localStorage.getItem("already_record_quiz_continue")){
      localStorage.removeItem("already_record_quiz_continue");
    }

   
 
  },

  methods: {
  
    async getBlocksArrayForIsrealChartClient() {
      try {
        const blocksArray = await axios.get(`/api/loadQuiz`, {
          params: {
            api_token: this.QuizKey,
          },
        });
        return blocksArray.data.data.totalPages;
      } catch (error) {
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      }
    },
    async PopulateResultPreviewDataVuex(resultKey) {
      this.isLoading = true;
      this.resetQuizStarted();
      try {
        let data = {
          result_key: resultKey,
        };
        let firstPage;
        const response = await axios.post(`/api/getQuizResultHistory`, data);
        if (response.status == 200 && response.data.status == "success") {
          if (this.$route.query.email) {
            localStorage.setItem(
              "already_record_email",
              this.$route.query.email
            );
            localStorage.setItem("already_record_status", true);
          }
          // this.isResultPreview = true;
          const totalPages = [response.data.data.result_page];
          const previewSettings = response.data.data.setting;
          const leadsData = response.data.data.lead;
          this.resultPreviewProducts = response.data.data.products;

          if (response.data.data?.questionAnswers) {
            this.populateSetSelectedOptions(
              response.data.data?.questionAnswers
            );
          }

          window.quizell.allQuestions = totalPages;
          if (totalPages.length > 0) {
            firstPage = totalPages[0];
          }
          await this.setTotalPreviewPages(totalPages);
          await this.setSelectedPage(JSON.parse(JSON.stringify(firstPage)));

          if (
            this.isrealChartClientIds.includes(
              response.data.data.setting.quizId
            )
          ) {
            let dataBlocks = await this.getBlocksArrayForIsrealChartClient();
            await this.setTotalPreviewPages(dataBlocks);
          }
          await this.setSavedLeadID(leadsData);
          await this.setTranslation(previewSettings.translation);
          await this.setPreviewSettings(previewSettings);
          if(previewSettings.selected_fonts){
            await  this.$store.commit("SET_FONT_FAMILY_TRACKER", previewSettings.selected_fonts);
            this.$store.dispatch("loadFontsFromTracker");
          } else {
           await this.$store.dispatch("processFontFamily", { totalPages });
          }
        } else if (response.status == 200 && response.data.status == "fail") {
          this.KeyNotValid = true;
        }
      } catch (error) {
        
        this.KeyNotValid = true;
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
        throw error
      } finally {
        this.isLoading = false;
      }
    },
    async initializePreviewData() {
    await this.PopulatePreviewDataVuex(this.QuizKey);
    await this.resetSelectedOptions();
    await this.resetRangeSliders();
  },
  
      async   updateQuizAnalyses(  ){
            try {
        
                let data = {
                    api_token: this.QuizKey,
                  quizId: this.getPreviewSettings.quizId,
                  value_type: "Started",
                };
        
                await  this.$store.dispatch(`updateQuizAnalysesAction`,data)
              } catch (error) {
                this.$toasted.show("Error occured ", {
                  theme: "toasted-primary",
                  type: "error",
                  position: "bottom-center",
                  duration: 2000,
                });
              }
        },
    resizeHandler(e) {
      if (e.target.window.innerWidth > 650) {
        this.isDesktopView = true;
      } else {
        this.isDesktopView = false;
      }
    },
    ...mapMutations([
      "setIsLoadingQuestion",
      "resetLeadCounter",
      "updatePageCounter",
      "ResetStoredData",
      "setSelectedPage",
      "setPreviewProgressBar",
      "setTotalPreviewPages",
      "setPreviewSettings",
      "resetSelectedOptions",
      "resetRangeSliders",
      "resetPreviewPagesStack",
      "RESET_STATE",
      "setTranslation",
      "resetQuizStarted",
      "resetSavedLeadObj",
      "resetPopulateTrackCounter",
      "populateSetSelectedOptions",
      "setSavedLeadID",
    ]),

    async PopulatePreviewDataVuex(QuizKey) {
      this.isLoading = true;
      this.resetQuizStarted();
   
      try {
        
        let data = {
          api_token: QuizKey,
        };
        if(this.$route.query.lang){
          data['lang'] = this.$route.query.lang
        }
        let firstPage;
        const response = await axios.get(`/api/loadQuiz`, { params: data });
        if (response.status == 200 && response.data.status == "success") {
           const pageCounter = response.data.data?.page_counter;
          const totalPages = this.rearrangeOrder(response.data.data.totalPages);
          const progressBarValue = response.data.data.progressBar;
          const previewSettings = response.data.data.setting;
          window.quizell.allQuestions=totalPages
          if (totalPages.length > 0) {
            firstPage = totalPages[0];
          }
          await this.updatePageCounter(pageCounter)
          await this.setTotalPreviewPages(totalPages);
          await this.setSelectedPage(JSON.parse(JSON.stringify(firstPage)));
          await this.setPreviewProgressBar(progressBarValue);
          await this.setTranslation(previewSettings.translation);
          await this.setPreviewSettings(previewSettings);
          if(previewSettings.selected_fonts){
            await  this.$store.commit("SET_FONT_FAMILY_TRACKER", previewSettings.selected_fonts);
            this.$store.dispatch("loadFontsFromTracker");
          } else {
           await this.$store.dispatch("processFontFamily", { totalPages, progressBarValue });
          }
          // this.setAnimationSpeed()
        } else if (response.status == 200 && response.data.status == "fail") {
          this.KeyNotValid = true;
        }
      } catch (error) {
        this.KeyNotValid = true;
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    rearrangeOrder(arr) {
      const tempArr = [...arr];
      const newArr = tempArr.sort((a, b) => a.order - b.order);
      return newArr;
    },

    setupAudio() {
      const soundURL='https://quizell.nyc3.cdn.digitaloceanspaces.com/audios/trippy-trivia.mp3'
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.gainNode = this.audioContext.createGain();
      this.gainNode.gain.value = 0.2;

      // Fetch and decode audio data, but don't start playing it yet
      fetch(soundURL)
        .then(response => response.arrayBuffer())
        .then(data => this.audioContext.decodeAudioData(data))
        .then(buffer => {
          this.buffer = buffer; // Store the audio buffer to use later
        })
        .catch(error => {
          console.error('Error loading audio:', error);
        });
    },
    
    playAudio() {
      if (this.buffer && !this.source) { // Only create a new source if it's not already playing
        this.source = this.audioContext.createBufferSource();
        this.source.buffer = this.buffer;
        this.source.connect(this.gainNode);
        this.gainNode.connect(this.audioContext.destination);
        this.source.loop = true;
        this.source.start();
      } else {
        console.log("Audio is already playing or not loaded yet.");
      }
    },

    stopAudio() {
      if (this.source) {
        this.source.stop();
        this.source.disconnect();
        this.source = null;
      }
    },
    initializeAudioOnUserGesture() {
      // Add a mousemove event listener to start the audio once the user interacts
      window.addEventListener('click', this.startAudioOnUserGesture, { once: true });
    },
    startAudioOnUserGesture() {
      this.playAudio(); // Start the audio when the mouse moves
      window.removeEventListener('click', this.startAudioOnUserGesture);
    },
  },
  watch: {

    getTotalPreviewPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
    },
    CurrentSelectedPage(newValue) {
     
       if(newValue.value=='resultPage'&&this.isKozimaGameQuestion)
       {
         this.stopAudio();
       }
      this.countComp++;
        window.quizell.selectedQuestionOptions=this.getSelectedOptionsFromVuex.questionAnswer;

    },
     GetCurrPageIndex(newVal,oldVal){
      
      let { quiz_analytic_id} = this.getSavedLeadID
      if (newVal == 1 && oldVal == 0 && !quiz_analytic_id) {
        this.updateQuizAnalyses();
      }
    }
  },
};
</script>

<style>
.default-bg {
  background: #cccace;
}
.cursorPointer {
  cursor: pointer;
}
.div-disable {
  pointer-events: none;
  opacity: 0.4;
}
.image-division {
  height: 100%;
  /* display: flex;
    align-items: center;
    justify-content: center; */
}
.ql-editor {
  min-height: auto !important;
  height: auto !important;
}
.ql-container {
  height: auto !important;
  min-height: auto !important;
}
.sub-title {
  font-size: 14px;
  color: #8c898f;
}
.ImageInputBlock:hover {
  opacity: 0.6;
  /* background-color:rgba(255, 255, 255, 0.3) !important; */
}
.SelectedImageStyle {
  position: absolute;
  top: 0;
  right: 0;

}
.page-setting {
  padding: 15px 0px 0px;
}
.page-setting-btn,
.page-setting-btn i {
  color: #8c898f;
  font-weight: 500;
}
.close-icon {
  position: absolute;
  right: 23px;
  top: 23px;
  background: transparent;
  border: none;
}
.deleteBlock {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  z-index: 10;
}
#preview div {
  word-break: break-word !important;
}
.navbar__icon-container.active {
  background: rgb(179 175 182 / 30%) !important;
}

.big-div {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
  /* padding: 0px; */
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
}
@media only screen and (max-width: 767px) {
  .big-div {
    width: 100% !important;
    /* padding: 0px 15px; */
  }
  .navbar__left,
  .navbar__middle,
  .navbar__right {
    margin-bottom: 20px;
  }
  .page-style {
    height: 100%;
  }
}

.Tab:hover {
  transform: translate3d(0, -3px, 0);
  -webkit-transform: translate3d(0, -3px, 0);
}

</style>
